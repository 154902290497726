html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #bd43f6;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c770f0;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #c770f0 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 0.5rem 1rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo-container {
  height: 50px; /* Adjust the height as needed */
}

.logo-container img {
  height: 120px; /* Make the image fill the container */
  width: auto; /* Let the width adjust automatically to maintain aspect ratio */
  margin-top: 0px !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #bd43f6;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* ---------------- */
/* PinLogin section */
/* ---------------- */

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(5px); /* Apply blur effect to background */
  z-index: 9999; /* Ensure the overlay is on top of other content */
}

/* Login Container */
.pin-login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  z-index: 10000; /* Ensure the login container is on top of the overlay */
}

/* Custom Password Input */
.password-input {
  display: flex;
  justify-content: center;
}

.password-input input[type="password"] {
  width: 50px;
  height: 50px;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  margin: 5px;
}


/* --------- */
/* Home section */
/* --------- */


.home-header {
  padding-top: 80px !important;

}

.home-section {
  position: relative;
  /* background-image: var(--image-gradient), url(./Assets/home-bg.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  background-color: transparent !important;
}

.home-content {
  padding: 9rem 0 5rem !important;
  color: whitesmoke;
  text-align: center;
  /* Set a fixed width */
  width: 100%; /* Adjust as needed */
  max-width: 100%; /* Adjust as needed */
  margin: 0; /* Center horizontally */
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

@media (max-width: 767px) {
  .home-content {
    padding: 6rem 1rem 2rem !important; /* Adjust padding for smaller screens */
  }

  .heading {
    font-size: 1.8em !important; /* Adjust font size for smaller screens */
    padding-left: 20px !important; /* Adjust padding for smaller screens */
  }

  .heading-name {
    font-size: 2em !important; /* Adjust font size for smaller screens */
    padding-left: 20px !important; /* Adjust padding for smaller screens */
  }

  .Typewriter__wrapper {
    font-size: 1.2em !important; /* Adjust font size for smaller screens */
  }

  .myAvtar {
    padding-top: 4em !important; /* Adjust padding for smaller screens */
    padding-bottom: 4em !important; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}


/* --widgets settings----------------------------------------------------- */
.widget {
  color: white;
  padding: 20px; /* Adjust padding for smaller screens */
  margin: 30px; /* Add margin between widgets */
  transition: transform 1.3s ease, box-shadow 1.3s ease;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(30% - 50px); /* Set initial width for each widget */
  background-position: center ; /* Center the background image */
  border-radius: 50%;

}

.widget:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 50px #ab5fbd;
}

.iframe {
  padding: 10px;
  transition: transform 1.3s ease, box-shadow 1.3s ease;
}

.iframe:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 50px #ab5fbd;
}


/* --widgets settings for small screens----------------------------------- */
@media screen and (max-width: 768px) {
  .widget {
      flex-basis: calc(50% - 20px); /* Make widgets occupy 50% of the container width on small screens */
  }
}

/* --widgets settings for extra small screens------------------------------- */
@media screen and (max-width: 480px) {
  .widget {
      flex-basis: calc(100% - 20px); /* Make widgets occupy 100% of the container width on extra small screens */
  }
}

.widget a {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
}

/* -- Specific widget background images -----------------------------------
/* .widget.kids {
  background-color: #8a49a8;
  background-image: url('./Assets/kids.jpg'); 
}

.widget.senior {
  background-color: #8a49a8;
  background-image: url('../images/dashboard/volunteer.jpeg'); 
} */

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
  position: fixed;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
